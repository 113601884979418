async function get(url) {
	let response = await fetch(url);
	let data = await response.json()
	try {
		return data;
	} catch (error) {
		throw error;
	}
}

export { get };